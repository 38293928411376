<template>
  <el-container class="role-manager">
    <el-aside class="role-list-aside">
      <role-list ref="roleList" @select="selectRole" />
    </el-aside>
    <el-main class="role-edit-pane">
      <div v-if="currentRoleId > 0">
        <basic-role-info :role-id="currentRoleId" @changed="roleListChanged" />

        <el-tabs class="power-tabs" v-model="activeTabName">
          <el-tab-pane class="power-pane" label="应用权限" name="app-power">
            <app-power :role-id="currentRoleId" />
          </el-tab-pane>
          <el-tab-pane class="power-pane" label="菜单权限" name="menu-power">
            <menu-power :role-id="currentRoleId" />
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="empty-div" v-else>
        <el-empty description="从左边选择一个角色进行编辑"></el-empty>
      </div>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "role-manager",
  data() {
    return {
      activeTabName: "app-power",
      currentRoleId: 0,
    };
  },
  methods: {
    roleListChanged() {
      this.$refs.roleList.refresh();
    },
    selectRole(roleId) {
      this.currentRoleId = roleId;
    },
  },
  components: {
    "role-list": () => import("./RoleList"),
    "basic-role-info": () => import("./BasicRoleInfo"),
    'menu-power': () => import('./MenuPower'),
    'app-power': () => import('./AppPower')
  },
};
</script>

<style lang="less">
.role-manager {
  height: 100%;

  .role-list-aside {
    height: 100%;
    border-right: 1px #cccccc solid;
  }

  .role-edit-pane {
    padding-top: 0;

    .empty-div {
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>